import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";
import { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";

class PaginatedScrollableList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentPage: 0,
    };
  }

  showMore = () => {
    this.setState((prevState) => ({
      currentPage: prevState.currentPage + 1,
    }));
  };

  render() {
    var componentsToShow = this.props.componentList
      .slice(
        0,
        Math.min(
          (this.state.currentPage + 1) * this.props.pageSize,
          this.props.componentList.length
        )
      )
      .map((component) => {
        return component;
      });

    return (
      <>
        <Row>
          <Col className="modalCardCol">{componentsToShow}</Col>
        </Row>
        <Row className="seeMoreDiv">
          {(this.state.currentPage + 1) * this.props.pageSize <
          this.props.componentList.length ? (
            <Button className="seeMoreButton" onClick={this.showMore}>
              See More
            </Button>
          ) : null}
        </Row>
      </>
    );
  }
}

PaginatedScrollableList.propTypes = {
  componentList: PropTypes.array,
  pageSize: PropTypes.number,
};

export default PaginatedScrollableList;
