import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";
import { Component } from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import PaginatedScrollableList from "./PaginatedScrollableList";
import { eventsPageSize } from "./constants";

class SchedulerGroupEventModal extends Component {
  constructor(props) {
    super(props);
  }

  padTo2Digits(num) {
    return String(num).padStart(2, '0');
  }

  getHoursAndMinutes(date) {
    return this.padTo2Digits(date.getHours()) + ':' + this.padTo2Digits(date.getMinutes());
  }

  render() {
    var eventsToShow = this.props.groupOfEventsEvent.eventsThisHour.map(
      (event, index) => (
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Card.Title>{event.title}</Card.Title>
                <Card.Text>
                  {`${this.getHoursAndMinutes(event.start)} to ${this.getHoursAndMinutes(event.end)}`}
                </Card.Text>
                <Button
                  id={index}
                  onClick={this.props.seeEventDetailsFromGroupModal}
                  variant="primary"
                >
                  View more details
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )
    );

    return (
      <Modal
        show={this.props.showModal}
        onHide={this.props.hideModalFunction}
        backdrop={"static"}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{this.props.groupOfEventsEvent.modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body md="auto">
          <Row>
            <PaginatedScrollableList
              componentList={eventsToShow}
              pageSize={eventsPageSize}
            />
          </Row>
        </Modal.Body>
      </Modal>
    );
  }
}

SchedulerGroupEventModal.propTypes = {
  showModal: PropTypes.bool,
  hideModalFunction: PropTypes.func,
  groupOfEventsEvent: PropTypes.object, // contains eventsThisHour and title
  seeEventDetailsFromGroupModal: PropTypes.func,
};

export default SchedulerGroupEventModal;
